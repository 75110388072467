<script>
import { masterComputed, masterMethods, clientMethods, checkPermission } from '@/state/helpers';
import { showMessage } from '@/utils/messages';
import { InputText } from '@/components/Input';
import { cleanConfigFormData } from '@/utils/format';
import { initFormClientSupport } from './index';
import { validateAllField, validateField } from '@/utils/validate';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

export default {
    page: {
        title: 'エンドクライアント担当者登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer,
        InputText
    },
    data() {
        return {
            loading: false,
            client_id: this.$route.query.client_id,
            id: this.$route.query.id,
            form: initFormClientSupport(),
            clientData: {},
            arrRoleClient: ['client.register', 'client.edit']
        };
    },
    computed: {
        ...masterComputed
    },

    mounted() {
        if (this.$route.query.id && this.$route.query.client_id) {
            let data = {
                client_id: this.$route.query.client_id,
                id: this.$route.query.id
            };
            this.getDetail(data);
        }
        if (this.$route.query.client_id) {
            this.getDetailClient(this.$route.query.client_id);
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...clientMethods,

        getDetailClient(id) {
            this.loading = true;
            this.detailClient(id).then((data) => {
                this.clientData = data;
                this.loading = false;
            });
        },
        getDetail(id) {
            this.loading = true;
            this.detailClientSupport(id).then((data) => {
                this.form = {
                    ...this.form,
                    ...data
                };
                this.loading = false;
            });
        },

        handleSubmit() {
            let check = validateAllField(this.form);
            if (!check || this.loading) return;
            this.loading = true;
            let dataObject = cleanConfigFormData(this.form);
            dataObject.client_id = this.client_id;
            if (this.$route.query.id) {
                this.updateClientSupport(dataObject).then((data) => {
                    if (data.code == 200) {
                        showMessage(data.code, this.$bvToast, 'エンドクライアント担当者が更新されました。');
                        this.getDetailClient(this.$route.query.client_id);
                    } else {
                        showMessage(data.code, this.$bvToast, this.$t(data.message));
                        this.loading = false;
                    }
                });
            } else {
                this.registerClientSupport(dataObject).then((data) => {
                    if (data.code == 200) {
                        this.$router
                            .push({
                                path: '/client/form/support/view',
                                query: { id: data.data.id, client_id: this.client_id }
                            })
                            .then(() => {
                                showMessage(data.code, this.$bvToast, 'エンドクライアント担当者が登録されました。');
                            });
                    } else {
                        if (data.message === 'name.unique') {
                            showMessage(data.code, this.$bvToast, this.$t('notificationCommon.exitPersonInCharge'));
                        } else {
                            showMessage(data.code, this.$bvToast, this.$t(data.message));
                        }
                    }
                    this.loading = false;
                });
            }
        },

        handleValidateField(configField) {
            validateField(configField);
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-8">
                                <form class="needs-validation" @submit.prevent="handleSubmit" autocomplete="off">
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">会社名</label>
                                        <div class="col-sm-9">
                                            {{ clientData.name }}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('clientSupport.department') }}</label>
                                        <div class="col-sm-9">
                                            <InputText
                                                id="department"
                                                :model.sync="form.department"
                                                :config.sync="form.configFieldDepartment"
                                                @blur="handleValidateField(form.configFieldDepartment)"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('clientSupport.name') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <InputText
                                                id="name"
                                                :model.sync="form.name"
                                                :config.sync="form.configFieldName"
                                                @blur="handleValidateField(form.configFieldName)"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('clientSupport.kana_name') }}</label>
                                        <div class="col-sm-9">
                                            <InputText
                                                id="kana_name"
                                                :model.sync="form.kana_name"
                                                :config.sync="form.configFieldKanaName"
                                                @blur="handleValidateField(form.configFieldKanaName)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">メールアドレス</label>
                                        <div class="col-sm-9">
                                            <InputText
                                                id="email"
                                                :model.sync="form.email"
                                                :config.sync="form.configFieldEmail"
                                                @blur="handleValidateField(form.configFieldEmail)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('clientSupport.phone_number') }}</label>
                                        <div class="col-sm-9">
                                            <InputText
                                                id="phone_number"
                                                :model.sync="form.phone_number"
                                                :config.sync="form.configFieldPhoneNumber"
                                                @blur="handleValidateField(form.configFieldPhoneNumber)"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">携帯番号</label>
                                        <div class="col-sm-9">
                                            <InputText
                                                id="mobile_number"
                                                :model.sync="form.mobile_number"
                                                :config.sync="form.configFieldMobileNumber"
                                                @blur="handleValidateField(form.configFieldMobileNumber)"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light btn-sm mr-3"
                            @click="
                                $router.push({
                                    path: '/client/form/view',
                                    query: { id: client_id }
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            @click="handleSubmit"
                            class="btn btn-primary ml-3"
                            v-if="checkPermission(arrRoleClient[0]) || checkPermission(arrRoleClient[1])"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
